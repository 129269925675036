import _ from 'lodash';
import React from 'react';
import { useDispatch } from 'react-redux';
import AiChatBotComponent, { Mode, ProxyApp } from '@tylertech/ai-chatbot-component';

import { replaceVif, setVisualizationType } from '../../../actions';
import { generateVif } from 'common/authoring_workflow/helpers/aiVifHelper';

const AiChatBot = ({ metadata }) => {
  const dispatch = useDispatch();

  const onUpdateVif = (vif) => {
    const chartType = _.get(vif, 'series[0].type', 'barChart');
    dispatch(setVisualizationType(chartType));
    dispatch(replaceVif(vif));
  };

  const generateVifFunction = ({ vif, vifActionType }) => {
    const newVif = vifActionType == 'update' ? vif : generateVif(vif);
    onUpdateVif(newVif);
  };

  const createVifContext = () => {
    if (!metadata || !metadata.data) return {};

    // Get schema information from columns
    const columns = _.get(metadata, 'data.columns', []);
    const schema = columns.map((column) => ({
      // Legacy fields
      columnName: column.fieldName || column.name,
      dataType: column.renderTypeName || column.dataTypeName,
      // New fields
      name: column.name,
      dataTypeName: column.dataTypeName,
      fieldName: column.fieldName,
      description: column.description
    }));

    // Get sample data - limit to maximum of 10 rows
    const allRows = _.get(metadata, 'data.rows', []) || [];
    const sampleData = allRows.slice(0, 10);

    // Format dates safely
    let createdAt = '';
    if (metadata.data.createdAt) {
      createdAt =
        typeof metadata.data.createdAt === 'string'
          ? metadata.data.createdAt
          : new Date(metadata.data.createdAt).toISOString();
    }

    let updatedAt = '';
    if (metadata.data.updatedAt) {
      updatedAt =
        typeof metadata.data.updatedAt === 'string'
          ? metadata.data.updatedAt
          : new Date(metadata.data.updatedAt).toISOString();
    }

    // Get domain name from window.location
    const domainName = window.location.hostname || metadata.domain || '';

    // Create the context object
    return {
      domainName: domainName,
      datasetName: metadata.data.name || '',
      datasetId: metadata.data.id || '',
      datasetUid: metadata.datasetUid || '',
      sampleData: sampleData,
      schema: schema,
      columns: schema,
      datasetOwnerName: _.get(metadata, 'data.owner.displayName', ''),
      datasetCreatedAt: createdAt,
      datasetUpdatedAt: updatedAt,
      viewCount: typeof metadata.data.viewCount === 'number' ? metadata.data.viewCount : 0
    };
  };

  const vifContext = createVifContext();
  const proxyApp = window.location.pathname.includes('/stories/') ? ProxyApp.STORYTELLER : ProxyApp.FRONTEND;

  return (
    <div id="ai-chatbot-container">
      <AiChatBotComponent
        mode={Mode.VIF}
        actionFunction={generateVifFunction}
        appContext={vifContext}
        internalControlInitialOpenState={true}
        proxyApp={proxyApp}
      />
    </div>
  );
};

export default AiChatBot;
